<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="">
      <form
        class="form-horizontal"
        @submit.prevent="addingToilletage"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <fieldset> 
          <animal-detail :show-detail="true" />
          <div
            class="row"
            style="padding: 20px 10px;"
          >
            <div class="col-12">
              <div class="form-group">
                <label for="activities">Activités</label>
                <select
                  id="activities"
                  name="activities"
                  class="form-control"
                  v-select="{placeholder: 'Selectionnez les activités effectuées lors du toilettage'}"
                  v-model="selectedActivities"
                  multiple
                >
                  <option value="" />
                  <option
                    v-for="(a, index) in sortedActivities"
                    :value="a.uid"
                    :key="index"
                  >
                    {{ a.libelle }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="dateVaccination">Date de l'opération</label>
                <datepicker
                  id="dateVaccination"
                  name="dateVaccination"
                  input-class="form-control required"
                  :disabled-dates="disabledToilettageDate"
                  format="dd/MM/yyyy"
                  placeholder="dd/mm/yyyy"
                  v-model="dateToilettage"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="dateRapel">Date de Rappel</label>
                <datepicker
                  id="dateRapel"
                  name=""
                  input-class="form-control required"
                  :disabled-dates="disabledRappelToilettageDate"
                  format="dd/MM/yyyy"
                  placeholder="dd/mm/yyyy"
                  v-model="dateRapel"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="info">Information supplémentaire</label>
                <textarea
                  rows="5"
                  class="form-control"
                  v-model="info"
                />
              </div>
            </div>
          </div>
        </fieldset>
                
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
              :class="loading? 'disabled': ''"
            > 
              <span
                class="spinner-border m-1"
                role="status"
                v-if="loading"
              /> 
              <span v-else> Ajouter </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
    
    <script>
    import Datepicker from 'vuejs-datepicker'
    // import {fr} from 'vuejs-datepicker/dist/locale/index'
    import { mapGetters, mapMutations } from 'vuex'
    import navbar from '../../../components/navbar.vue'
    import animalDetail from '../../../components/identification/animal/detailInform.vue'
    import { ADD_TOILETTAGE } from '../../../graphql/sanitaire'
    export default {
        components: {navbar, animalDetail, Datepicker},
        data(){
            return {
              navbarItems: [
                  {
                      libelle: 'Sanitaire'
                  },
                  {
                      libelle: 'Toilettages',
                      route: 'toilettage'
                  },
                  {
                    libelle: 'Ajout'
                  }
                  ],
                  pageIcon: 'la-bath',
                  pageTitle: 'Ajout toilettage',
                  pageDescription: 'Ajout toilettage', 
                selectedActivities: [],
                dateToilettage: new Date(),
                dateRapel: new Date(),
                info: null,
                has_error: false,
                error_msg: null
            }
        },
        mounted(){
          this.initForm()
        },
        methods: {
            ...mapMutations({
                done: 'DONE',
                setLoading: 'SET_LOADING'
            }),
            initForm(){
              this.setLoading(false)
              this.dateToilettage = new Date()
              this.dateRapel = new Date()
              this.activities = []
              this.info = null
            },
            addingToilletage(){
                this.setLoading(true)
                let data = {
                    animal: this.selectedAnimal,
                    activities: JSON.stringify(this.selectedActivities),
                    info: this.info,
                    dateToilettage: this.dateToilettage,
                    dateRappel: this.dateRapel,
                }
                console.log(data)
                this.$apollo.mutate({
                  mutation: ADD_TOILETTAGE,
                  variables: {
                      "toilettage": {
                          ...data
                      }
                  },
                  update: () => {
                      console.log('Add Toilettage success')
                  }
                }).then(() => {
                  this.initForm()
                  this.$router.push({name: 'toilettage'})
              }).catch((error) => {
                  this.setLoading(false)
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
            }
        },
        computed: {
            ...mapGetters({
                activities: 'sanitaire/toilettageActivities',
                animaux: 'identification/animaux',
                selectedAnimal: 'identification/selectedAnimal',
                loading: 'loading'
            }),
            sortedActivities(){
              return [...this.activities].sort((a,b) => a.libelle.localeCompare(b.libelle))
            },
            disabledToilettageDate () {
                let animal = [...this.animaux].find(item => item.uid === this.selectedAnimal)
                if(animal !== null && animal !== undefined){
                    return {
                        to: new Date(animal.dateNaissance),
                        from: new Date()
                    }
                }
                return {
                    to: new Date(),
                    from: new Date()
                }
            },
            disabledRappelToilettageDate () {
              return {
                to: this.dateToilettage
              }
            },
        }
    }
    </script>
    
    <style>
    
    </style>